.current-date {
  display: inline;
  margin-left: 128px;
  font-size: 0.9rem;
  margin-top: 5px;
  padding: 3px;
  text-decoration: underline;
  letter-spacing: 0.41px;
  font-weight: bold;
}

.current-date:hover {
  cursor: pointer;
}
