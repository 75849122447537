.article-img {
  height: auto;
  width: 100%;
  max-width: 420px;
  border-radius: 4px;
  background-color: #f1f3f5;
  display: block;
}

.thumbnail-text {
  font-size: 14px;
  overflow-wrap: break-word;
  font-weight: bold;
}

.article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 8px;
}
