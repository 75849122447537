* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  color: #343a40;
  font-family: 'Inter', sans-serif;
  border-bottom: #eee;
}

.app {
  display: flex;
  flex-direction: column;
  gap: 96px;
}

footer {
  border-top: 1px solid #adb5bd;
  font-size: 12px;
  margin: 36px auto 12px auto;
  display: flex;
  flex-direction: column;
}

.footer-links {
  display: flex;
  font-size: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}
