.main-container {
  display: grid;
  margin-left: 48px;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  grid-column: 1/-1;
  margin-right: 48px;
}

.results {
  border: #1864ab solid 2px;
  border-radius: 4px;
  padding-right: 7px;
  padding-left: 7px;
  padding-bottom: 7px;
}

.news-articles {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 30px;
}

.standings {
  margin-top: -20px;
}

@media screen and (max-width: 600px) {
  .results {
    width: fit-content; /* The width fits the, when the viewport is 600px or smaller */
  }

  .standings {
    display: none;
  }
}
