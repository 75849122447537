.header-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  grid-template-rows: repeat(2, 1fr);
  margin-top: 48px;
  margin-left: 48px;
}

#home {
  font-size: 36px;
  cursor: pointer;
  color: #343a40;
  padding: 8px 8px;
  font-family: 'Inter', sans-serif;
  text-decoration: none;
  margin-left: 48px;
  line-height: 1.2;
  letter-spacing: 0.1px;
}

.app-title {
  border-bottom: 1px solid #adb5bd;
}

#links-bar {
  display: flex;
  margin-left: 48px;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  flex: 1 1;
  width: 500px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.7px;
}

#links-bar a:link,
#links-bar a:visited {
  padding: 12px 12px;
  text-decoration: none;
  color: #343a40;
}

#links-bar a.active,
#links-bar a:hover {
  background-color: #1864ab;
  color: #fff;
  border-radius: 8px;
}

.ionicon {
  display: inline;
}

@media screen and (max-width: 600px) {
  .app-title {
    font-size: 1.75rem;
    margin-top: 5px;
  }
  .nav-item {
    font: 6px;
  }

  .links-bar {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -10px;
  }

  .nav-item {
    font-size: 14px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
