.score-card {
  display: grid;
  grid-template-columns: 1fr 32px 1fr 16px;
  grid-template-rows: repeat(2, 1fr) 16px;
}

.game-time {
  grid-column: 1/2;
  grid-row: 2/3;
  margin-top: 0px;
  align-self: center;
  margin-left: 12px;
  font-weight: bold;
}

.countries {
  margin-left: 12px;
  font-weight: bold;
}

.league-names,
.zero-games {
  font-size: 12px;
  font-weight: bold;
  margin-top: 16px;
  background: #1864ab;
  color: #fff;
  border-radius: 4px;
  padding: 4px;
  margin-left: 8px;
}

.team1 {
  font-weight: bold;
  font-size: 16px;
  grid-column: 3/4;
  grid-row: 2/-2;

  margin-left: -128px;
  margin-top: 16px;
}

.team1-result {
  font-weight: bold;
  grid-column: 4/5;
  grid-row: 2/-2;

  margin-left: -148px;
  margin-top: 16px;
}

.team1-logo {
  grid-column: 2/3;
  grid-row: 2/-2;

  margin-left: -128px;
  margin-top: 16px;
}

.team2 {
  font-weight: bold;
  font-size: 16px;
  grid-column: 3/4;
  grid-row: -2/-1;

  margin-left: -128px;
  margin-top: -24px;
}

.team2-result {
  font-weight: bold;
  grid-column: 4/5;
  grid-row: -2/-1;

  margin-left: -148px;
  margin-top: -24px;
}

.team2-logo {
  grid-column: 2/3;
  grid-row: -2/-1;

  margin-left: -128px;
  margin-top: -24px;
}
.countries {
  font-size: 12px;
}

.game-time {
  font: bold;
  font-size: 0.7rem;
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
}

@media screen and (max-width: 600px) {
  .team1,
  .team2,
  .team1-result,
  .team2-result {
    font-size: 0.8rem;
  }
}
