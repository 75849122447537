.team-pos,
.team-name,
.team-win,
.team-loss,
.table-header,
.standing-info {
  font-size: 1rem;
  letter-spacing: 0.41px;
}

.table-responsive {
  border-radius: 32px;
  border: #1864ab solid;
  padding: 24px 8px 12px 8px;
}

.nav-tabs li button {
  font-size: 0.8rem;
  letter-spacing: 0.41px;
  background: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.nav-tabs {
  width: fit-content;
  padding: 5px;
  margin: 100px auto;
  border-bottom: none;
}

@media screen and (max-width: 600px) {
  /* .table,
  .table-responsive {
    display: none;
  } */
}
